<template>
  <b-modal
    ref="add-output-modal"
    title="Add output"
    ok-title="Add"
    centered
    size="lg"
    cancel-variant="outline-secondary"
    @hidden="resetModal"
    @ok="handleOk"
  >
   <b-tabs v-model="outputTab" content-class="mt-2" justified>
    <b-tab title="Basic Outputs">
      <TemplateConfigItems 
      :externalData="externalData"  
      :configItems="basicOutputonfig" />
    </b-tab>
    <b-tab title="Advanced Outputs">
      <TemplateConfigItems 

      :configItems="advancedOutputonfig" />
    </b-tab>
   </b-tabs>


  
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select';
import { required, email } from '@validations'
import {configs} from "./configs";
import TemplateConfigItems from "./TemplateConfigItems.vue";
import {
    BModal,
  BFormGroup,
  BFormInput,
  BTab,
  BTabs,
  
} from "bootstrap-vue";
export default {
    props: ["organizations", "externalData"],
    components: {
      TemplateConfigItems,
        BTab,
  BTabs,
      vSelect, BModal, BFormGroup, BFormInput,  ValidationProvider, ValidationObserver},
  data() {
    return {
      outputTab: 0,
      basicOutputonfig: null,
      advancedOutputonfig: null,
    }
  },
  methods: {
    showModal(externalData) {
      this.basicOutputonfig = JSON.parse(JSON.stringify(configs["basicOutputs"]));
      this.advancedOutputonfig = JSON.parse(JSON.stringify(configs["advancedOutputs"]));
      this.$refs["add-output-modal"].show();
    },
    checkFormValidity() {

    },
    resetModal() {

    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
      this.resetModal();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      const output = {};
      if(this.outputTab == 0){
        this.basicOutputonfig.forEach(element => {
          output[element.name] = element.value;
        })
      }
      else if(this.outputTab == 1){
        this.advancedOutputonfig.forEach(element => {
          output[element.name] = element.value;
        })
      }

      
      this.$emit('on-add-output', output)
    

      this.$nextTick(() => {
        this.$refs["add-output-modal"].toggle("#toggle-btn");
      });
    },
  },
};
</script>