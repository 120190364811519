<template>
  <div>


    <div
      v-for="(confItem, outerIndex) in configItems"
      :key="confItem.name + 'i' + outerIndex"
     
    >
      <TemplateConfigItemsInputs 
      :class="innerClass" :externalData="externalData" :confItem="confItem" />
      

    </div>
  </div>
</template>

<script>

import TemplateConfigItemsInputs from "./TemplateConfigItemsInputs.vue"
export default {
    name: "TemplateConfigFactory",
  components: {
    TemplateConfigItemsInputs
  },

  props: ["configItems", "innerClass", "externalData"],
};
</script>
