<template>
    <div>
      <b-card>
        <b-card-title class="mt-1 mb-75">
          Outputs
        </b-card-title>
        <div class="demo-inline-spacing">
            <b-button @click="$refs['add-output'].showModal()" variant="primary" block>+</b-button>
        </div>
        <div class="demo-inline-spacing">
            <draggable
            :list="outputs"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
            >
            <b-list-group-item
                v-for="(listItem, index) in outputs"
                :key="index"
                tag="li"
            >
                <div class="d-flex">

                    <div >
                        <div class="mb-0 font-weight-bold">
                        {{ listItem.name }}
                        </div>
                        <small>Type: {{ types[listItem.type] || listItem.type }}</small>
                        <div>
                          <small>Related Inputs: {{ listItem.relatedInputs }}</small>
                        </div>
                        <div v-if="listItem.description">
                          <small>Description: {{ listItem.description }}</small>
                        </div>
                    </div>
                </div>
            </b-list-group-item>
            </draggable>
        </div>
      </b-card>
        <AddOutputModal :externalData="externalData" ref="add-output" @on-add-output="addOutput" />
    </div>
</template>
<script>
import {
  BListGroupItem, BAvatar, BRow, BCol,BButton, BCard, BCardTitle
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import AddOutputModal from "./AddOutputModal.vue"
export default {
  props: ["externalData"],
  components: {
    BAvatar,
    BListGroupItem,
    BRow,
    BCol,
    Prism,
    draggable,
    BButton,
    AddOutputModal,
    BCard,
    BCardTitle
  },
  methods: {
    addOutput(output){
        this.outputs.push(output);
    }
  },
    data(){
        return {
          types: {
            "title": "Title",
            "text": "Text",
            "list": "List",
            "image": "Image"
          },
            outputs: [
            ]
        }
    }
}
</script>
<style scoped>
    .list-group {
        width: 100%;
    }
    .list-group-item {
      margin-bottom: 10px;
    }
</style>