<template>
    <div>
        <b-row>
            <b-col md="6">
                <EditorInputControl :inputs="inputs" />
            </b-col>
            <b-col md="6">
                <EditorOutputControl :externalData="externalData" />
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { BRow, BCol} from "bootstrap-vue";
import EditorInputControl from "./EditorInputControl.vue"
import EditorOutputControl from "./EditorOutputControl.vue"
export default {
    data(){
        return {
            inputs: []
        }
    },
    computed: {
        externalData(){
            return {inputs: this.inputs.map(x=> x.name)}
        }
    },
    components: {BRow, BCol, EditorInputControl, EditorOutputControl}
}
</script>