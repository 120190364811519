export const configs = {
    "inputs": [
        {
            "type": "text",
            "name": "name",
            "title": "Name",
            "value": '',
            "validationRule": "required"
        },
        {
            "type": "select",
            "name": "type",
            "title": "Type",
            "options": [{ value: 'input', text: 'Short Text' }, { value: 'textarea', text: 'Long Text' }, { value: 'image', text: 'Image' }, { value: 'csvFile', text: 'CSV File' }, { value: 'jsonFile', text: 'JSON File' }, { value: 'radiolist', text: 'Radio List' }, { value: 'checkboxlist', text: 'Checkbox List' }, { value: 'checkbox', text: 'Checkbox' }, { value: 'select', text: 'Select' }, { value: 'multiselect', text: 'Multi Select' }, { value: 'switch', text: 'Switch' }, { value: 'formtag', text: 'Tags Text' }],
            "value": 'input',
            "validationRule": "required"
        },
        {
            "type": "textarea",
            "name": "description",
            "title": "Description",
            "value": '',
        },
        {
            "type": "tags",
            "name": "options",
            "title": "Options",
            "conditional": "type==radiolist||type==checkboxlist||type==select||type==multiselect",
            "value": []
        },

    ],
    "basicOutputs": [
        {
            "type": "text",
            "name": "name",
            "title": "Name",
            "validationRule": "required",
            "value": ''
        },
        {
            "type": "select",
            "name": "type",
            "title": "Type",
            "options": [{ value: 'text', text: 'Text' },{ value: 'title', text: 'Title' }, { value: 'list', text: 'List' }, { value: 'image', text: 'Image' }],
            "value": 'text'
        },
        {
            type: "tagsOptions",
            name: "relatedInputs",
            title: "Related Inputs",
            actionLabel: "Choose Inputs",
            "loading": "getRelatedInputs",
            options: [],
            value: []
        },
        {
            "type": "textarea",
            "name": "description",
            "title": "Description",
            "value": '',
        },

    ],
    "advancedOutputs": [
        {
            "type": "select",
            "loading": "getAdvancedOutputs",
            "name": "selected",
            "title": "Advanced Outputs",
            "options": [],
            "value": ""
        },
        {
            "type": "setValue",
            "depend": "selected",
            "name": "name",
            "value": "" 
        }

    ]
}