<template>
  <b-modal
    no-close-on-backdrop
    ref="add-input-modal"
    title="Add input"
    ok-title="Add"
    centered
    cancel-variant="outline-secondary"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <TemplateConfigItems 
    
    :configItems="inputsConfig" />

  
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select';
import { required, email } from '@validations'
import {configs} from "./configs";
import TemplateConfigItems from "./TemplateConfigItems.vue";
import {
    BModal,
  BFormGroup,
  BFormInput,
  
} from "bootstrap-vue";
export default {
    props: ["organizations"],
    components: {
      TemplateConfigItems,
      vSelect, BModal, BFormGroup, BFormInput,  ValidationProvider, ValidationObserver},
  data() {
    return {

      inputsConfig: null,
    }
  },
  methods: {
    showModal() {
      this.inputsConfig = JSON.parse(JSON.stringify(configs["inputs"]));
      this.$refs["add-input-modal"].show();
    },
    checkFormValidity() {

    },
    resetModal() {

    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
      this.resetModal();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      const input = {};
      this.inputsConfig.forEach(element => {
        input[element.name] = element.value;
      })
      
      this.$emit('on-add-input', input)
    

      this.$nextTick(() => {
        this.$refs["add-input-modal"].toggle("#toggle-btn");
      });
    },
  },
};
</script>