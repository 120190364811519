<template> 

    <TemplateConfigFactory 
      :innerClass="'mb-1'"
    :externalData="externalData"
    :configItems="selectedNodeConfigItems" />
</template>

<script>

import TemplateConfigFactory from "./TemplateConfigFactory.vue";
export default {

  components: {
    TemplateConfigFactory
  },
  props: ["configItems", "externalData"],
  
  computed: {
    selectedNodeConfigItems() {
      return this.filterHiddenConfigs(this.configItems);
    },
  },
  methods: {
   
    filterHiddenConfigs(configList) {
      let self = this;
      let res = [];
      let kvm = {};
      configList.forEach(function (itm) {
        // some weird bug
        if (!itm) {
          return;
        }

        if (itm && "name" in itm && "value" in itm) {
          kvm[itm["name"]] = itm["value"];
        }
      });

      configList.forEach(function (itm) {
        // some weird bug
        if (!itm) {
          return;
        }

        let shouldinclude = true;
        if ("conditional" in itm) {
          let conditions = itm["conditional"];
          let conditionList = [conditions];
          let operator = "";

          if (conditions.includes("||")) {
            conditionList = conditions.split("||");
            operator = "or";
          } else {
            if (conditions.includes("&&")) {
              conditionList = conditions.split("&&");
              operator = "and";
            }
          }

          shouldinclude = operator == "and" ? true : false;

          conditionList.forEach(function (cond) {
            if (cond.includes("!=")) {
              let condsplit = cond.split("!=");
              let attrName = condsplit[0];
              let attrVal = condsplit[1];
              if(attrVal == "null"){
                attrVal = null
              }
              if(attrVal == "undefined"){
                attrVal = undefined
              }
              if(attrVal == "false"){
                attrVal = false
              }
              if(attrVal == "true"){
                attrVal = true
              }
              if (operator == "and") {
                shouldinclude = shouldinclude && !(kvm[attrName] == attrVal);
              } else {
                shouldinclude = shouldinclude || !(kvm[attrName] == attrVal);
              }
              return;
            }

            if (cond.includes("==")) {
              let condsplit = cond.split("==");
              let attrName = condsplit[0];
              let attrVal = condsplit[1];
              if (operator == "and") {
                shouldinclude = shouldinclude && kvm[attrName] == attrVal;
              } else {
                shouldinclude = shouldinclude || kvm[attrName] == attrVal;
              }
              return;
            }

            if (cond.includes(">")) {
              let condsplit = cond.split(">");
              let attrName = condsplit[0];
              let attrVal = condsplit[1];
              if (operator == "and") {
                shouldinclude = shouldinclude && kvm[attrName] > attrVal;
              } else {
                shouldinclude = shouldinclude || kvm[attrName] > attrVal;
              }
              return;
            }

            if (cond.includes("<")) {
              let condsplit = cond.split("<");
              let attrName = condsplit[0];
              let attrVal = condsplit[1];
              if (operator == "and") {
                shouldinclude = shouldinclude && kvm[attrName] < attrVal;
              } else {
                shouldinclude = shouldinclude || kvm[attrName] < attrVal;
              }
              return;
            }
          });
        }

        if (shouldinclude) {
          if (itm["type"] == "itemlist") {
            let tempItm = JSON.parse(JSON.stringify(itm));
            tempItm._original = itm;
            let tempItmlist = [];

            for (let i = 0; i < itm["items"].length; i++) {
              tempItmlist.push(self.filterHiddenConfigs(itm["items"][i]));
            }

            tempItm["items"] = tempItmlist;
            res.push(tempItm);
          } else {
            res.push(itm);
          }
        }
      });

      return res;
    },
   
  }
};
</script>