<template>
    <div>
      <b-card>
        <b-card-title class="mt-1 mb-75">
          Inputs
        </b-card-title>
        <div class="demo-inline-spacing">
            <b-button @click="$refs['add-input'].showModal()" variant="primary" block>+</b-button>
        </div>
        <div class="demo-inline-spacing">
            <draggable
            :list="inputs"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
            >
            <b-list-group-item
                v-for="(listItem, index) in inputs"
                :key="index"
                tag="li"
            >
                <div class="d-flex">

                    <div >
                        <div class="mb-0 font-weight-bold">
                        {{ listItem.name }}
                        </div>
                        <small>{{ types[listItem.type] || listItem.type }}</small>
                        <div v-if="listItem.description">
                          <small>Description: {{ listItem.description }}</small>
                        </div>
                    </div>
                </div>
            </b-list-group-item>
            </draggable>
        </div>
      </b-card>
        <AddInputModal ref="add-input" @on-add-input="addInput" />
    </div>
</template>
<script>
import {
  BListGroupItem, BAvatar, BRow, BCol,BButton, BCard, BCardTitle
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import AddInputModal from "./AddInputModal.vue"
export default {
  props: ["inputs"],
  components: {
    BAvatar,
    BListGroupItem,
    BRow,
    BCol,
    Prism,
    draggable,
    BButton,
    AddInputModal,
    BCard,
    BCardTitle
  },
  methods: {
    addInput(input){
        this.inputs.push(input);
    }
  },
    data(){
        return {
          types: {
            "input": "Short Text",
            "textarea": "Long Text",
            "image": "Image",
            "csvFile": "CSV File",
            "jsonFile": "JSON File",
            "radiolist": "Radio List",
            "checkboxlist": "Checkbox List",
            "checkbox": "Checkbox",
            "select": "Select",
            "multiselect": "Multi Select",
            "switch": "Switch",
            "formtag": "Tags Text",
          }
        }
    }
}
</script>
<style scoped>
    .list-group {
        width: 100%;
    }
    .list-group-item {
      margin-bottom: 10px;
    }
</style>