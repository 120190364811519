<template>
  <div>
    <validation-observer ref="simpleRules">
      <form ref="addform">
      <div v-if="confItem.type == 'text'">
        
        
        <b-form-group :label="confItem.title">
          <validation-provider
            #default="{ errors }"
            :name="confItem.title"
            :rules="confItem.validationRule"
          >
            <b-form-input
              type="text"
              :placeholder="confItem.title"
              :disabled="confItem.disabled"
              :state="errors.length > 0 ? false:null"
              class="form-control"
              v-model="confItem.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>

      <div v-if="confItem.type == 'textarea'">
        <b-form-group :label="confItem.title">
          <validation-provider
            #default="{ errors }"
            :name="confItem.title"
            :rules="confItem.validationRule"
          >
          <b-form-textarea
            class="contilt-textarea"
            id="textarea-auto-height"
            debounce="500"
            :state="errors.length > 0 ? false:null"

            :placeholder="confItem.title"
            rows="3"
            max-rows="8"
            v-model="confItem.value"
          />
          <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          
        </b-form-group>
      </div>

      <div v-if="confItem.type == 'float'">
        <label for="basic-password">{{ confItem.title }}</label>
        <div role="group" class="input-group">
          <input
            type="number"
            placeholder=""
            class="form-control"
            v-model.number="confItem.value"
          />
        </div>
      </div>

      <div v-if="confItem.type == 'boolean'">
        <b-form-checkbox
          v-model="confItem.value"
          name="check-button"
          switch
          inline
        >
          {{ confItem.title }}
        </b-form-checkbox>
      </div>
      <div v-if="confItem.type == 'select'">
        <label>{{ confItem.title }}</label>
        <v-select @input="setSelected($event, confItem)" 
              label="text" @open="onSelectOpen(confItem)" :reduce="option => option.value" v-model="confItem.value" :options="confItem.options" >
          <template slot="option" slot-scope="option">
            <span>{{ option.text }}</span>
            <span class="select-description-text" v-if="option.description"><br>{{ option.description }}</span>
          </template>
        </v-select>
      </div>
      <div v-if="confItem.type == 'multiselect'">
        <label>{{ confItem.title }}</label>
        <v-select label="text" @open="onSelectOpen(confItem)" :reduce="option => option.value" v-model="confItem.value" :options="confItem.options" multiple />
      </div>
      <div v-if="confItem.type == 'tags'">
        <label>{{ confItem.title }}</label>
        <b-form-tags
          v-model="confItem.value"
          :placeholder="confItem.title"
          class="mb-2"
        />
      </div>
      <div v-if="confItem.type == 'tagsOptions'">
        <label>{{ confItem.title }}</label>
        <b-form-tags
          v-model="confItem.value"
          :placeholder="confItem.title"
          class="mb-2"
          no-outer-focus>
          <template v-slot="{ tags, disabled, addTag, removeTag }">
            <ul
              v-if="tags.length > 0"
              class="list-inline d-inline-block mb-1"
            >
              <li
                v-for="tag in tags"
                :key="tag"
                class="list-inline-item"
              >
                <b-form-tag
                  :title="tag"
                  :disabled="disabled"
                  variant="primary"
                  @remove="removeTag(tag)"
                >
                  {{ tag }}
                </b-form-tag>
              </li>
            </ul>

            <b-dropdown
              size="sm"
              variant="outline-secondary"
              block
              menu-class="w-100"
            >
              <template v-slot:button-content>
                <b-icon icon="tag-fill" /> {{confItem.actionLabel || "Choose tags"}} 
              </template>
              <b-dropdown-form @submit.stop.prevent="() => {}">
                <b-form-group
                  label-for="tag-search-input"
                  label="Search tags"
                  label-cols-md="auto"
                  class="mb-0"
                  label-size="sm"
                  :description="searchDesc"
                  :disabled="disabled"
                >
                  <b-form-input
                    id="tag-search-input"
                    v-model="search"
                    type="search"
                    size="sm"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-dropdown-form>
              <b-dropdown-divider />
              <b-dropdown-item
                v-for="option in availableOptions"
                :key="option"
                @click="onOptionClick({ option, addTag })"
              >
                {{ option }}
              </b-dropdown-item>
              <b-dropdown-text v-if="availableOptions.length === 0">
                There are no tags available to select
              </b-dropdown-text>
            </b-dropdown>
          </template>
        </b-form-tags>
      </div>
      <div v-if="confItem.type == 'itemlist'">
        <div
          v-for="(innerItm, innerIndex) in confItem.items"
          style="position: relative"
          :key="'item oi'+ innerIndex"
          class="inner-card"
        >
          <div
            v-if="confItem.disableRemoveDefault && innerIndex>0"
            style="position: absolute; top: 0px; right: 0px; overflow: visible"
          >
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-danger"
              class="btn-icon"
              @click="removeConfigItem(confItem._original, innerIndex)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </div>
          <TemplateConfigFactory :externalData="externalData" :innerClass="'inner-card-item'" :configItems="innerItm" /> 

        </div>
        <div v-if="confItem.addMoreText" style="width: 100%; padding-top: 10px">
          <b-button
            style="width: 100%"
            @click="addNewItm(confItem._original)"
            variant="gradient-primary"
          >
            {{ confItem.addMoreText }}
          </b-button>
        </div>
      </div>
      </form>
      </validation-observer>
    </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,
  BSpinner,
  BButton,
  BFormTags,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormInput,
  BAlert,
  BForm,
  BIcon,
  BDropdown,BDropdownForm,BDropdownDivider,BDropdownItem,BFormTag,BDropdownText
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from 'vue-select';
import axios from "@axios";
import {
  required, min, confirmed,
} from '@validations'
export default {
  name: "TemplateConfigItemsInputs",
  data(){
    return {
      search: '',
      required, min, confirmed,
    }
  },
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    BFormTags,
    BFormSelect,
    BFormCheckbox,
    BFormTextarea,
    BFormValidFeedback,
    BFormInvalidFeedback,
    vSelect,
    BAlert,
    BSpinner,
    BForm,
    BDropdown,BDropdownForm,BDropdownDivider,BDropdownItem,BFormTag,BDropdownText,
    ValidationProvider, ValidationObserver,
    BIcon,
    TemplateConfigFactory: () => import('./TemplateConfigFactory.vue'),
  },
  directives: {
    Ripple,
  },
  props: ["configItems", "confItem", "inputs", "externalData"],
  computed: {
    organization() {
            return this.$route.query.organization;
    },
    templateId() {
      return this.$route.params.templateId;
    },
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions() {
      if(!this.confItem.type == "tagsOptions" ){
        return [];
      }
      const { criteria } = this
      let optionss = this.confItem.options;

      if(this.confItem.loading && this.confItem.loading == "getRelatedInputs" && this.externalData && this.externalData.inputs) {
        optionss = this.externalData.inputs
      }

      // Filter out already selected options
      const options = optionss.filter(opt => this.confItem.value.indexOf(opt) === -1)
      if (criteria) {
        // Show only options that match criteria
        return options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1)
      }

      // Show all options available
      return options
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'There are no tags matching your search criteria'
      }
      return ''
    },
  },
  methods: {
    onOptionClick({ option, addTag }) {
      addTag(option)
      this.search = ''
    },
    addNewItm(itemListObject) {
      itemListObject.items.push(
        JSON.parse(JSON.stringify(itemListObject.defaultTemplate))
      );
    },
    removeConfigItem(itemListObject, index) {
      itemListObject.items.splice(index, 1);
    },
    setSelected(value, configItem) {
      if(!configItem.loading){
        return
      }
      if (!value) {
        this.$emit("set-inputs-outputs", [], []);
        return;
      }
      const options = configItem.options;
      const option = options.find((x) => x.value == value);
      if (option && option.inputs && option.outputs) {
        this.$emit("set-inputs-outputs", option.inputs, option.outputs);
      }
      else if(option && option.inputs){
        this.$emit("set-inputs-outputs", option.inputs, []);
      }
      else if(option && option.outputs){
        this.$emit("set-inputs-outputs", [], option.outputs);
      }
    },
    
    onSelectOpen(configItem){
      if(configItem.loading){
        if(configItem.loading == "getAdvancedOutputs"){
          axios
            .get(`/templates`, {
              params: {
                sourceReference: this.templateId,
                selectedFields: "id name documentation",
                onlyTemplates: 1
              }
            }).then(res => {
              configItem.options = res.data.templates.results.map(x=>{
                return {
                  text: `${x.name}`,
                  description: `${x.documentation.description}`,
                  value: x.id
                }
              })
            })
        }
        
      } 
    }
  }
};
</script>

<style>
.inner-card {
  background-color: rgba(0, 0, 0, 0.035);
  padding: 5px;
  border-radius: 10px;
  margin-top: 10px;
}
.select-description-text {
  font-size: 12px !important;
  color: #7e7e7e;
}
.inner-card-item {
  margin: 5px;
}
.b-form-tags-input::placeholder {
  color: #ccccd4;
}
</style>
